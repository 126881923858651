import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import FAQAccord from "../../components/GFlush";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import ContentImg from "../../components/GSTTemp/ContentImg";
import Cta from "../../components/Cta";
import SliderContent from "../../components/GSTTemp/SliderContent";
import Package from "../../components/GSTTemp/Package";

export default function TrademarkRectification() {
  /* Slider */
  // const rectSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 50,
  //     background: `linear-gradient(139.4deg, rgb(198, 243, 242) 28.54%, rgb(181, 201, 253) 104.43%)`,
  //     header: `Online Trademark Rectification`,
  //     content: `Best Online Trademark Rectification Service in Hosur`,
  //     image: "/imgs/registration/ipr/td1.png",
  //     alt_tag: "Best Online Trademark Rectification Service in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Trademark Rectification`,
    buyBtnLink: `#pricing-buy`,
    price: `2999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/bregistration/pancard.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/bregistration/register.png",
        alt_tag: "Professional Tax Service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `Free Trademark
        Search Report`,
        icon: "/imgs/icons/bregistration/audit.png",
        alt_tag: "Trademark Search Report",
        background: "bg-eb6b3d",
        plus: "+",
      },
    ],
  };
  /* About Data With Form*/
  const rectAboutData = {
    header: `Online Trademark Rectification`,
    sub_title: `Best online trademark rectification service providers in Hosur.`,
    content_paragraph: [
      `Trademark rectification refers to any type of modification, change, rectification, or alteration 
      made to a registered trademark or to the Trademark Register. Rectification is the legitimate 
      process to rectify or repair a mistake or omission that was made in the specifics of a trademark 
      as reported in the trademark registry after the trademark registration.`,

      // `The Indian Trademark Act has developed a system for handling instances like this called 
      // "rectification filed in trademark." Trademark rectification refers to any adjustments, corrections, or modifications made 
      // to a registered trademark or to the Register of Trademarks.`,
    ],
  };
  /* Scroll Nav Data */
  const rectScrollId = [
    {
      id: `#filing`,
      heading: `Filling`,
    },
    {
      id: `#process`,
      heading: `Processes`,
    },
    {
      id: `#result`,
      heading: `Result`,
    },
    {
      id: `#impact`,
      heading: `Impact`,
    },
    {
      id: `#avoid-rectification`,
      heading: `Avoid Rectification`,
    },
  ];

  /* Img Content Component Data */
  const rectIcData = {
    id: "filing",
    mt_div: "",
    mt_img: "",
    header: "Filing Trademark Objection",
    image: "/imgs/registration/ipr/td4.png",
    alt_tag: "Trademark Rectification Procedure",
    points: [
      {
        content: `In most cases, the trademark owner will register it as having fixed the particular error.`,
        icon: true,
      },
      {
        content: `Additionally, it might be documented by another person or thing that is breaking 
        the law in question.`,
        icon: true,
      },
      {
        content: `Note:Particularly in light of the removal of any listed trademark of another person
         or thing from the register of trademarks, the candidate must put together persuasive proof.`,
        icon: true,
      },

    ],
  };

  /* Content Form Component Data */
  const rectCfData = {
    id: "process",
    mt_div: "mt-5",
    mt_img: "mt-5",
    header: "Trademark Rectification Process",
    content: ``,
    body: [
      {
        head: `Providing of documents`,
        points: `The owner must submit the appropriate application(s) together with the necessary modifications, 
        as well as any supporting documentation (depending on the necessary modifications, such as identification
           or address verification).`,
        icon: true,
      },
      {
        head: `Creating the application's proposal`,
        points: `The candidate must then completely fill out the applicable form.Make sure that your application 
        for a revised trademark includes a clear, updated explanation of the basis for the amendment.`,
        icon: true,
      },
      {
        head: `Submitting the form`,
        points: `The form must then be submitted to the Registrar along with the required fees,with the form TM-O is
         the application structure that is utilized to mention a trademark amendment and is threefold documented.`,
        icon: true,
      },
      {
        head: `Government approval`,
        points: `The Registrar will then notify the trademark owner to make a counter statement, and both parties will 
        be asked to provide affidavits with supporting information. The Registrar or the Appellate Board may decide to 
        modify, add to, or delete the entry as a result of rectification upon hearing both parties' arguments. Under the 
        Trademark Act and Rules, the trademark may be amended or revoked by either the applicant or the party that was mistreated.`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const rectCiData = {
    id: "result",
    background: "",
    mt_div: "",
    header: "Results Of Valid Trademarks Rectification",
    paragraph: ``,
    points: [
      {
        content: `If it is demonstrated that the trademark was registered without a sincere goal and that there 
        has been no verifiable use of the trademark up to a date.`,
        icon: true,
      },
      {
        content: `Three months prior to the date of use and five 
        years have passed since registration and still no use, the trademark may be mentioned to be removed from 
        the register.`,
        icon: true,
      },
      {
        content: `The five-year period for a student's grade has been proposed to be calculated beginning on the day
         the grade was really recorded in the register, such as the date the enrolment was completed.`,
        icon: true,
      },
      {
        content: `After receiving an application, the Registrar notifies the listed 
        proprietor to record a counter verbalization.`,
        icon: true,
      },
    ],
    image: "/imgs/business/dsc1-lib.png",
    alt_tag: "Results Of Valid Trademarks Rectification",
  };

  var FAQ_data = [
    {
      header: "Who may file a Trademark Rectification Petition?",
      body: [
        {
          content: `Any person who finds fault with the trademark registration may
          request correction by pointing out the irregularities in the
          registration.`,
          icon: false,
        },
      ],
    },
    {
      header: "What is the process of trademark rectification?",
      body: [
        {
          content: `The process for application for rectification can be divided into three categories: Rectification
           or cancellation initiated by the proprietor of the trademark – Apply in the form “TM-16” along with 
           prescribed fees. Rectification or cancellation initiated by the registrar – Form “TM- M” along with 
           prescribed fees.`,
          icon: false,
        },
      ],
    },

    {
      header: "What does it mean when a trademark is cancelled?",
      body: [
        {
          content: `The registrar's misrepresentation, a false entry, a registration
          fee that was not paid, or an error during registration are all
          grounds for rectification.`,
          icon: false,
        },
      ],
    },
    {
      header: "When can we file rectification of trademark?",
      body: [
        {
          content: `When can we file rectification of trademark?
          Trademark Rectification can be filed in India on grounds of contravention or failure to observe a condition 
          of the trade mark already entered in the Register or an error in registering the trade mark. Section 57 of the 
          Trade Marks Act, 1999 provides for the grounds for Rectification of the Register.`,
          icon: false,
        },
      ],
    },
    {
      header: "What are documents of rectification?",
      body: [
        {
          content: `A rectification deed is a legal instrument that allows you to correct any errors or mistakes in 
          documents like sale deeds and title deeds. It is referred to as a correction deed, confirmation deed, 
          supplementary deed or amendment deed.`,
          icon: false,
        },
      ],
    },
    {
      header: "How many times rectification can be done?",
      body: [
        {
          content: `Can a rectification request be withdrawn or filed again, once submitted? No, you are not allowed 
          to withdraw rectification requests already submitted. You can file another rectification request only after 
          the submitted one is processed in CPC.`,
          icon: false,
        },
      ],
    },
    {
      header: "What does it mean when a trademark is cancelled?",
      body: [
        {
          content: `The term "trademark cancellation" refers to a request that is
          appealed to have a recently registered trademark (or service mark)
          removed from the register of trademarks.`,
          icon: false,
        },
      ],
    },
    {
      header:
        " How much does the government charge for major trademark exchange?",
      body: [
        {
          content: `The petitioned withdrawal of any recently registered trademark (or
            service mark) from the register of trademarks is referred to as
            trademark cancellation.`,
          icon: false,
        },
      ],
    },
  ];

  /* Slider */
  const imgSlider = [
    '/imgs/business/form-filing.png',
    '/imgs/business/checklist.png',
    '/imgs/business/compreg.png',
  ];

  /*  Slider Content Component Data */
  const TrScData = {
    id: 'impact',
    background: "main-content",
    mt_div: 'mt-3',
    mt_img: 'mt-5',
    header: 'Impact of rectification on the Trademark',
    paragraph: `They can drop, remove, or correct the trademark
    listing in the registry after hearing the solicitation
    by offering the affected parties equal opportunity.`,
    content: [
      {
        head: "Rectification Affect Trademark",
        points: `If the registered brand name has not been used for
        at least five years, Trademark Rectification may
        be required.`,
        icon: true,
      },
      {
        points: `Where the registration was gained through
        falsification of facts, such as an older mark
        listed, it requires a convincing justification for
        registration.`,
        icon: true,
      },
      {
        points: ` Situations where the mark was incorrectly kept on
        the register and forced or required to cause
        conflict.`,
        icon: true,
      },
      {
        points: `The specific prohibition against entry, such as
        disclaimer, requirement or restriction. whenever the re-establishment 
        cost is still owed.`,
        icon: true,
      },
    ],
    images: imgSlider,
  };

  /*  Content Img Component Data */
  const RtCiData = {
    id: "avoid-rectification",
    background: "",
    mt_div: "",
    header: "How to Avoid Trademark Rectification?",
    paragraph: ``,
    points: [
      {
        content: `Putting forth every effort to protect the mark's
        distinctive nature and prevent it from becoming
        misleading.`,
        icon: true,
      },
      {
        content: ` As soon as any infringement is noticed, take
        immediate and legal action.Maintain your trademark
        registration renewals.`,
        icon: true,
      },
      {
        content: `Cancellation of the previous trademark According
        to the most recent data.`,
        icon: true,
      },
      {
        content: `Relentless use of the mark can result in
        dismissal, as can non-constant use over an
        extended period of time.`,
        icon: true,
      },
    ],
    image: "/imgs/assited/penalty.png",
    alt_tag: "Avoid Trademark Rectification",
  };

  return (
    <div>
      <Seo
        title='Trademark Rectification'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={rectSlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={rectAboutData} />

          <Counter />
          <ScrollNav scroll_data={rectScrollId} />
          <ImgContent item={rectIcData} />
          <section style={{ marginTop: '50px' }}></section>
          <ContentForm CFSection_data={rectCfData} />
          {/* <div className="pt-5 mt-5"></div> */}
          <ContentImg CISection_data={rectCiData} />
          <section style={{ marginTop: '50px' }}></section>
          <SliderContent ScSection_data={TrScData} />
          <ContentImg CISection_data={RtCiData} />

          <Cta />
          <Guidance />

          <Testimonial />
          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
